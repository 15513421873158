.tire {
  &__detail {
    display: grid;
    justify-items: center;
    grid-template-columns: 3fr 2fr 2fr;
  }

  &__title {
    margin-top: .5rem;
  }

  &__content {
    display: flex;
    align-items: center;
    flex-flow: column;
  }
}