.overview {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__tire-details {
    display: flex;
    flex-flow: column;

    span {
      height: auto;
      line-height: 1.35;
    }
  }

  &__badge {
    width: 1.25rem;
    height: 1.25rem;
    min-width: 1rem;
    min-height: 1rem;
    border-radius: 50%;
    margin-right: .5rem;
    border: 1px solid rgba(0, 0, 0, .5);

    &--green {
      background-color: $color-green;
    }

    &--red {
      background-color: $color-red;
    }

    &--orange {
      background-color: $color-orange;
    }
  }

  &__custom {
    span {
      line-height: 1;
      display: block;
      padding: 0;
      margin: 0 0 .225rem 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &--top, &--bottom {
      line-height: 1;
      display: block;
      padding: 0;
      margin: 0 0 .225rem 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &--bottom {
      display: flex;
      align-items: center;

      span:first-child {
        margin-right: .225rem;
      }
    }
  }

  &__task-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__day {
    color: $color-lightgray;
    display: block;
  }

  &__new {
    margin-bottom: 1rem;
    width: 100%;

    button {
      width: 100%;

      @include respond-to(small) {
        width: auto;
      }
    }
  }

  &__data {
    .MuiDataGrid-columnHeaderTitle {
      font-weight: $font-weight-bold !important;
    }
  }
}

[data-field="newTires"] {
  justify-content: space-between !important;
}
