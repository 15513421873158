.circle-picker {
  width: 100% !important;
  margin-bottom: 3rem !important;

  div[title] {
    &:after {
      content: " ";
      position: absolute;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      width: 75%;
      height: 75%;
      display: block;
      background-color: $color-white;
      opacity: 1;
      transform-origin: center center;
      transition: transform 350ms ease;
    }

    &.active {
      position: relative;
      border: 2px solid $color-gray;

      &:after {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }
}