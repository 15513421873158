.progress {
  &__wrapper {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: $color-white;
    padding: $vertical-space 0;
    box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 99;
  }

  &__title {
    @extend .text__title-small;
    color: $color-gray;
  }

  &__header {
    padding-top: 1rem;
    @include respond-to(small) {
      padding-top: 0;
    }
  }

  &__subtitle {
    @extend .progress__title;
    color: $color-lightgray;
    font-weight: $font-weight-regular;
  }
}