.customer {
  &__suggestions {
    list-style-type: none;
    padding: 0;
    margin: 0 0 1rem 0;

    li {
      @extend .text__link;
    }
  }
}