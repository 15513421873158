// Variables

// CUSTOM VARIABLES

// Colors
$color-white: #ffffff;
$color-black: #121212;
$color-gray: #27230b;
$color-darkgray: #505050;
$color-lightgray: rgba(0, 0, 0, 0.54);
$color-yellow: #FFC900;
$color-red: #ff5a00;
$color-green: #84cc00;
$color-orange: #ff9f00;
$color-blue: #87B2E0;

$color-input: rgba(0, 0, 0, 0.09);
$color-input-border: rgba(0, 0, 0, 0.42);

// Z-indexes
$layer-feedback: 100;

// Fonts
$font-family-base: "Gilroy", sans-serif;
$font-weight-regular: 400;
$font-weight-bold: 600;
$font-weight-extrabold: 800;

// Heights
$header-yellow: .625rem;

// Transitions
$hover-transition-btn: 250ms;

// Grid
$columns-amount: 24;
$container-max-width: 1680px;

$gutter-width: 10px;
$gutter-width-tiny: 10px;
$gutter-width-small: 10px;
$gutter-width-medium: 20px;
$gutter-width-large: 20px;
$gutter-width-huge: 20px;
$gutter-width-full: 20px;

$vertical-space: 10px;
$vertical-space-tiny: 10px;
$vertical-space-small: 10px;
$vertical-space-medium: 20px;
$vertical-space-large: 20px;
$vertical-space-huge: 20px;
$vertical-space-full: 20px;