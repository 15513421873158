@use "sass:math";

.task {
  &__top{
    @extend .text__task;
    display: flex;
    justify-content: space-between;

  }
  &__svg{
    width: 50px;
    margin: 0 20px;
  }
  &__title {
    @extend .text__title;
    margin-bottom: 2rem;
  }

  &__done{
    &--svg{
      display: flex;
      align-items: center;
      justify-content: center;
      svg{
        height: 60vh;
        margin-bottom: 20px;
      }
    }
    &--text{
      width: 80%;
      div{
        width: 100%;
      }
    }
    &--bottom{
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 40%;
      div{
        margin-bottom: 20px;
      }
    }
  }
  &__config {
    background-color: $color-input;
    border: 1px solid $color-input;
    margin: $gutter-width $gutter-width $gutter-width 0;
    padding: $gutter-width;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    &--frame{
      padding: 0 15px 15px;
    }
    &--yellow{
      @extend .task__config;
      background-color: $color-yellow;
    }
    &--white {
      background-color: $color-white;
    }
    &--round{
      @extend .text__title-small;
      border-radius: 25px;
      display: flex;
      text-align: center;
      align-self: center;
    }
    &--tire{
      display: flex;
      flex-direction: row !important;
    }
    &--checkbox{
      display: flex;
      justify-content: center;
      align-items: center;
      background: $color-yellow;
      border-radius: 20px;
      max-height: 60px;
      padding: 0;
      margin-right: 30px;
    }

    @include respond-to(medium) {
      padding: $gutter-width-medium;
    }

    .config__title {
      @extend .text__title-small;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .config__additional{
      border-top: black dotted 2px;
      display: flex;
      justify-content: center;
      svg{
        width: 50px;
        margin: 0 20px;
      }
    }

    .config__comment {
      @extend .task__config;
      background-color: $color-white;
      margin-bottom: 0;

      .page__text {
        margin-bottom: 0;
      }
    }

    .config__car {
      text-align: center;
      width: 12rem;
      height: 20rem;
      display: flex;
      justify-content: center;
      position: relative;
      margin: 2em 0;

      .circle {
        @extend .text__paragraph;
        font-size: 0.85rem;
        position: absolute;
        width: 4rem;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: .75rem solid;
        border-radius: 50%;
        background-color: $color-white;

        &--primary {
          border-color: $color-yellow;
        }

        &--gray {
          border-color: $color-gray;
        }

        &--top {
          top: 15%;
        }

        &--right {
          transform: translateX(80px);
        }

        &--bottom {
          bottom: 15%;
        }

        &--left {
          transform: translateX(-80px);
        }
      }

      svg {
        width: 12rem;
        height: 20rem;
      }
    }

    .config__grid {
      display: grid;
      grid-column-gap: $gutter-width;
      width: 100%;

      &-mobile {
        @include createGrid();
      }

      @include respond-to(medium) {
        @include createGrid();
        grid-column-gap: $gutter-width-medium;
      }

      &--mt {
        margin-top: 2em;
      }

      &--buy {
        grid-template-columns: 2fr 6fr 2fr 1fr;
      }

      &--start {
        align-items: flex-start;
      }

      &--center {
        align-items: center;
      }
    }
  }

  &__info {
    margin-bottom: 2rem;

    &--grid {
      display: grid;
      grid-template-columns: repeat(2, 50%);
      background-color: $color-lightgray;
      padding: $gutter-width;

      @include respond-to(medium) {
        padding: $gutter-width-medium;
      }
    }
  }

  &__tire {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: math.div($gutter-width, 2);
    hyphens: auto;

    @include respond-to(medium) {
      grid-column-gap: math.div($gutter-width-medium, 2);
    }

    .tire__value {
      @extend .text__paragraph;
      font-weight: $font-weight-bold;
      border-bottom: .0625rem solid $color-gray;
    }

    .tire__desc {
      @extend .text__table;
    }
  }

  &__subtitle {
    @extend .text__paragraph;
    font-weight: $font-weight-bold;
  }

  &__paragraph {
    @extend .text__paragraph;
  }

  &__detail {
    margin-bottom: 2em;

    .detail__title {
      @extend .text__title;
      margin-bottom: 1rem;
    }

    .tire {
      &:hover {
        cursor: default;
      }
    }
  }
}