.error {
  &__card {
    @extend .text__paragraph;
    padding: $gutter-width;
    background: $color-lightgray;
    @include respond-to(medium) {
      padding: $gutter-width-medium;
    }
  }

  &__title, &_desc {
    margin-bottom: 2rem;
  }

  &__cta {
    padding: 2.375rem 0 .375rem 0;
  }

  &__desc, &__cta {
    ul, li {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .btn__primary {
      text-decoration: none;
    }
  }
}