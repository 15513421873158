.custom-radio {
  display: grid;
  grid-column-gap: $gutter-width;

  @include respond-to(medium) {
    grid-column-gap: $gutter-width-medium;
  }

  @include createGrid();

  .select__item {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    border: .063rem solid $color-input-border;
    border-radius: .25rem;
    padding: .5em;
    transition: background-color 250ms ease;

    &--mb {
      margin-bottom: 1.5rem;
    }

    &:hover, &--selected {
      background-color: $color-input;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .item__title {
    @extend .text__title-small;
    font-weight: $font-weight-regular;
  }

  &__customer {
    span {
      display: block;
    }

    &--gray {
      color: $color-lightgray;
    }
  }
}