.header {
  margin-bottom: 2rem;

  @include respond-to(small) {
    margin-bottom: 3rem;
  }

  &__flex {
    display: flex;
    align-items: center;
  }

  &__title {
    @include responsive-font(1.6vw, 16px, 24px, 16px);
    font-family: $font-family-base;
    line-height: 1;
    color: $color-gray;
    font-weight: $font-weight-regular;

    &--sub {
      line-height: 1;
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
      }
    }
  }
}