.form {
  @include form-group-grid();

  &__full {
    width: 100%;
  }

  &__label, label {
    //  @extend .text__label;
    margin-bottom: .25rem;
    color: $color-black;
  }


  &__group {
    display: flex;
    flex-flow: column;
    width: 100%;
    margin-bottom: 1rem;

    //  Smaller inputs on reifengrösse
    &--small {
      input {
        width: 100%;
      }

      label {
        //    @extend .text__table;
      }
    }

    &--radio, &--checkbox {
      flex-flow: wrap;
      align-items: center;


      label {
        padding-left: 1rem;
        margin: 0;
      }
    }

    &--checkbox {
      @extend .text__input;
      margin-bottom: 0;
    }

    &--grid {
      display: grid;
      grid-column-gap: $gutter-width;

      @include respond-to(medium) {
        grid-column-gap: $gutter-width-medium;
      }
    }

    input, select, textarea {
      //  @extend .text__input;
      //  border: .125rem solid $color-black;
      //  border-radius: 0;
      //  height: 2.5rem;
      //   padding: .125rem .25rem;

      &:focus {
        //  outline: none;
        //  border-color: $color-gray;
      }
    }

    textarea {
      height: auto;
    }

    &.invalid {
      input, select, textarea {
        border: .125rem solid $color-red;
      }
    }

  }
}