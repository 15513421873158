.btn {

  &__primary {
    @extend .text__label;
    background-color: transparent;
    border: 2px solid $color-black;
    color: $color-black;
    padding: .375rem 1.25rem;
    transition: color $hover-transition-btn, background-color $hover-transition-btn;

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      background-color: $color-gray;
      color: $color-darkgray;
      border-color: $color-gray;

      &:hover {
        background-color: $color-gray;
        color: $color-darkgray;
        cursor: not-allowed;
      }
    }
  }

  &__yellow {
    background-color: $color-yellow;
    border-color: $color-yellow;

    &:hover {
      background-color: $color-gray;
      color: $color-yellow;
    }
  }
}