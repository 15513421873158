.table {
  width: 100%;

  td {
    @extend .text__table;
    text-align: left;
  }

  tr {
    height: 2.5rem;
    border-bottom: .063rem solid $color-black;
  }

  thead {
    th {
      @extend .text__table;
      font-weight: $font-weight-bold;
      text-align: left;
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: $color-lightgray;
      }
    }
  }

}