// Task detail border on grid item
.border {
  &--right {
    border-right: .0625rem solid $color-gray;

    &:last-child {
      border-right: none;
    }
  }
}

// Hide header of data grid
.hide__header {
  display: none !important;
}