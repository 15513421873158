.text {
  &__title {
    @include responsive-font(2.8vw, 24px, 32px, 28px);
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
    line-height: 1;
  }

  &__title-small {
    @include responsive-font(1.6vw, 14px, 16px, 16px);
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
    line-height: 1.25;
  }

  &__label {
    @include responsive-font(1.8vw, 16px, 24px, 18px);
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
    line-height: 1;
  }

  &__input {
    @include responsive-font(1.8vw, 16px, 24px, 18px);
    font-family: $font-family-base;
    font-weight: $font-weight-regular;
    line-height: 1.33;
  }

  &__paragraph {
    @include responsive-font(1.5vw, 14px, 15px, 15px);
    font-family: $font-family-base;
    font-weight: $font-weight-regular;
    line-height: 1.33;
  }

  &__table {
    @include responsive-font(1.4vw, 10px, 16px, 14px);
    font-family: $font-family-base;
    font-weight: $font-weight-regular;
    line-height: 1;
  }

  &__link {
    @include responsive-font(1.8vw, 16px, 24px, 18px);
    font-family: $font-family-base;
    font-weight: $font-weight-regular;
    line-height: 1.33;
    color: $color-black;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  &__task {
    @include responsive-font(1.5vw, 14px, 16px, 15px);
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
    line-height: 1.45;
    svg{
      width: 40px;
    }
  }
}