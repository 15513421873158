/* @import must be at top of file, otherwise CSS will not work */
/*@import url("//hello.myfonts.net/count/3bdc86");*/

@font-face {
  font-family: "Gilroy";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/Gilroy-Bold/font.woff2") format("woff2"),
    url("../../fonts/Gilroy-Bold/font.woff") format("woff");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/Gilroy-ExtraBold/font.woff2") format("woff2"),
    url("../../fonts/Gilroy-ExtraBold/font.woff") format("woff");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/Gilroy-Regular/font.woff2") format("woff2"),
    url("../../fonts/Gilroy-Regular/font.woff") format("woff");
}
