.page {
  &__title {
    @extend .text__title;
    margin-bottom: 1em;

    &--flex {
      display: flex;
      align-items: center;

      span:first-child {
        margin-right: 1rem;
      }
    }
  }

  &__subtitle {
    @extend .text__title-small;
    margin-bottom: 1em;

    &--mt {
      margin-top: 3em;
    }

    &--gray {
      color: $color-lightgray;
      font-weight: $font-weight-regular;
    }

    &--no-mb {
      margin-bottom: 0;
    }
  }
  &__label{
    @extend .text__input;
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    justify-content: center;
    &--left{
      @extend .page__label;
      width: 20%;
    }
    &--done{
      @extend .page__label;
      display: flex;
      justify-content: flex-start;
      height: 100%;

    }

  }

  &__text {
    @extend .text__paragraph;
    margin-bottom: 1em;

    &--mt {
      margin-top: 3em;
    }

    &--no-mb {
      margin-bottom: 0;
    }
  }
  &__order{
    @extend .text__input;
  }
}

// Default container classes
.container {
  &--mb {
    margin-bottom: 6rem;
  }
}