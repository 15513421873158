.branch {
  &__buttons {
    display: flex;
    grid-gap: 2rem;
    margin-bottom: 3rem;

    &--mt {
      margin-top: 2rem;
    }
  }
}