.admin {
  &__cards {
    margin-bottom: 3rem;
  }

  &__colors {
    .chrome-picker {
      margin: 0 auto;
    }
  }
}