.login {
  &__wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    // 1.5rem header padding.
    min-height: calc(75vh - #{$header-yellow});
  }
}