.to-check {
  display: flex;
  align-items: center;
  margin-bottom: 1em;

  &__content {
    &--flex {
      @extend .text__paragraph;
      display: flex;
      align-items: center;
    }
  }

  p {
    @extend .text__paragraph;
    line-height: 1;
    margin: 0;
    padding: 0;
  }
}