.feedback {
  &__wrapper {
    position: fixed;
    bottom: 2rem;
    right: 1rem;
    z-index: $layer-feedback;
  }

  &__trigger {
    display: flex;
    align-items: center;
    margin-right: 1rem;

    &:hover {
      .feedback__text {
        opacity: 1;
      }
    }
  }

  &__dialogue {
    width: calc(100vw - 40px);
    max-width: 350px;
    background-color: #eaeaea;
    border-radius: .5rem;
    margin-bottom: 1rem;


    .dialogue {
      &__form {
        padding: 1rem 1rem 0 1rem;
      }

      &__submit {
        @extend .text__title-small;
        background-color: $color-yellow;
        border: none;
        width: 100%;
        height: 3rem;
        border-bottom-right-radius: .5rem;
        border-bottom-left-radius: .5rem;
        transition: background-color 250ms ease;

        &:hover {
          cursor: pointer;
          background-color: darken($color-yellow, 4%);
        }
      }

      &__close {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 1rem;

        svg {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  &__error {
    background-color: $color-yellow;
    border-radius: 1rem;
    width: auto;
    height: 3rem;
  }

  &__icon {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: $color-yellow;
    display: flex;
    align-items: center;
    justify-items: center;
  }

  &__text {
    @extend .text__paragraph;
    padding: .5rem;
    opacity: 0;
    transition: opacity 350ms ease;
    transition-delay: 500ms;
  }
}