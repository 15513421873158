.delete-task {
  &__list {
    margin: 1rem 0;
    padding: 0;
    list-style-type: none;

    .list__item {
      @extend .text__title-small;
      padding: 1.125rem 1rem;
      align-items: center;
      justify-content: center;
      display: grid;
      grid-template-columns: 40% 60%;
      font-weight: $font-weight-regular;
      border-top: 1px solid rgba(0, 0, 0, .15);

      &:last-child {
        border-bottom: 1px solid rgba(0, 0, 0, .15);
      }

      .item__title {
        font-weight: $font-weight-bold;
      }
    }
  }
}