@import '@hi_digital/easy-flex';

@include easy-flex-breakpoint('tiny', 480px, 10px, 10px);
@include easy-flex-breakpoint('small', 768px, 10px, 10px);
@include easy-flex-breakpoint('medium', 1024px, 20px, 10px);
@include easy-flex-breakpoint('large', 1200px, 20px, 20px);
@include easy-flex-breakpoint('huge', 1440px, 20px, 20px);
@include easy-flex-breakpoint('full', 1680px, 20px, 20px);

// Grid
$columns-amount: 24;
$container-max-width: 1680px;

@include create-easy-flex((
        columns: $columns-amount,
        container-width: 100%,
        container-max-width: $container-max-width,
        gutter-width: 10px,
        vertical-space: 10px,
));

@include easy-add-class(tac) {
  text-align: center !important;
}

@include easy-add-class(tal) {
  text-align: left !important;
}

@include easy-add-class(tar) {
  text-align: right !important;
}

@include easy-add-class(row--align-center){
  align-items: center;
}