.task {
  &__detail {
    &--clean{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex: 2;
      div{
       width: 50%;
      }
    }
    &--overview{
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 30px;

      &--left{
        @extend .task__detail--overview;
        align-items: flex-end;
        padding: 0;
      }
      &--right{
        @extend .task__detail--overview;
        padding: 0;
      }
    }
    &--front{
      @extend .text__task;
      height: 49%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &--rear{
      @extend .text__task;
      height: 49%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .detail__status {
      display: flex;
      align-items: baseline;

      .page__subtitle {
        margin-right: 1rem;
        line-height: 1;
      }
    }

    .detail__accordions {
      margin: 2rem 0 0 0;
    }

    .accordion__header {
      @extend .page__subtitle;
      border-top: 1px solid $color-lightgray;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: .25rem 0;

      &:hover {
        cursor: pointer;
      }

      .accordion__icon {
        width: .75rem;
        transform: rotate(180deg);

        &--closed {
          transform: rotate(0deg);
        }
      }
    }

    .accordion__content {
      overflow: hidden;

      .page__subtitle {
        line-height: 1;
        margin-bottom: .25em;
      }
    }
    &__stoppwatch{
      @extend .text__input;
    }

    &--additional{
      @extend .text__label;
      color: green;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 80px;
      margin-bottom: 40px;

      svg{
        margin-left: 4px;
        height: 60px;
        color: black;
      }
      &--wrapper{
         display: flex;
         flex-direction: row;
         align-items: center;
         justify-items: center;
       }
    }
  }
}